
/* eslint-disable */
import SelectBox, { SelectBoxOptions } from "@/cloudfun/components/SelectBox.vue";
import CloudFun, { defineComponent, ref, reactive, PropType, Condition, Operator } from "@cloudfun/core";
import { watch } from "vue";
import { VxeTableProps } from "vxe-table";

interface Option {
  Title: string;
  Added: boolean;
  Ordinal: number;
  QuestionId: number;
}

export default defineComponent({
  name: "OptionTable",
  components: {
    SelectBox
  },
  props: {
    data: {
      type: Array as PropType<Option[]>,
      default: () => []
    },
    aid: Number,
    readonly: Boolean,
  },
  setup(props, { emit }) {
    const model = CloudFun.current?.model;
    const grid = ref<any>();
    const gridOptions = {
      props: {
        sortConfig: {
          defaultSort: { field: 'Ordinal', order: 'asc' }
        }
      } as VxeTableProps,
      insert() {
        const { row } = grid.value.insertAt({}, 0);
        grid.value.setActiveRow(row)
      }
    }

    const table = reactive({
      data: [] as Option[],
      isLoading: false,
      isEditing: false,
      formData: { Ordinal: 0, Added: false } as Option,
      selectedRow: null as Option | null,
      // insert() {
      //   table.formData = { OutSource: false, Fee: 0, PayStatus: 0, Ordinal: 0 };
      //   table.selectedRow = null;
      //   table.isEditing = true;
      // },
      update(row: Option) {
        table.formData = { ...row };
        table.selectedRow = row;
        table.isEditing = true;
      },
      delete(row: Option) {
        grid.value.remove(row);
      },
      async save() {
        // if (table.formData.SpeakerId) {
        //   const temp = await model?.dispatch("option/find", table.formData.SpeakerId)
        //   table.formData.Option = { Id: temp.Id, Name: temp.Name, Photo: null };
        //   //table.formData.AgendaId = props.aid!;
        // }

        await grid.value.insert(table.formData);
        grid.value.sort({ field: "Ordinal", order: 'asc' });

        //emit('refresh');
      },
      rules: {
        Title: [{ required: true }],
        Added: [{ required: true }],
        Ordinal: [{ required: true }],
      }
    })

    watch(() => props.data, async(value) => {
      console.log(value)
      // const SpeakerIds = value.filter(e => e.SpeakerId).map(e => e.SpeakerId);
      // const Speakers: Option['Option'][] = await model?.dispatch("option/query", { condition: new Condition("Id", Operator.In, SpeakerIds) });
      table.data = value.map(e => {
        // if (e.SpeakerId) {
        //   const option = Speakers.find(a => a && a.Id === e.SpeakerId)
        //   if(option) e.Option = { Id: option!.Id, Name: option!.Name, Photo: null };
        // } else {
        // }
        return e;
      }).sort((a, b) => a.Ordinal - b.Ordinal);
    }, { immediate: true });

    const speakerForm = ref<any>();

    const userSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "Name",
      valueField: "Id",
      columns: [
        {
          field: "Name",
          title: "姓名",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        }
      ],
      showHeader: true,
      promises: {
        find: value => model!.dispatch("option/find", value), // eslint-disable-line
        query: params => model!.dispatch("option/query", params) // eslint-disable-line
      }
    };
    return {
      table,
      grid,
      gridOptions,
      speakerForm,
      userSelectorOptions
    }
  },
  methods: {
    getData() {
      return this.grid.getTableData().fullData;
    }
  }
})
