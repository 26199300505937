
/* eslint-disable */
import CloudFun, { Condition, defineComponent, Operator, ref, reactive, Sorting, SortOrder, PropType } from '@cloudfun/core'
import Grid, { GridOptions } from '@/cloudfun/components/Grid.vue'
import { VxeFormProps, VxeTableProps } from 'vxe-table'
import formatDate from 'xe-utils/toDateString'
import OptionTable from "./OptionTable.vue";

export default defineComponent({
  components: {
    Grid,
    OptionTable
  },
  props: {
    sid: Number,
    promises: Object as PropType<{
      /** 非同步查詢 */
      query?: (params: {
        page: number;
        pageSize: number;
        keyword?: string;
        sortings?: Sorting[];
        condition?: Condition;
      }) => Promise<{ data: any[]; totalCount: number }>;
      /** 查詢所有資料 */
      queryAll?: (params?: {
        keyword?: string;
        sortings?: Sorting[];
        condition?: Condition;
      }) => Promise<any[]>;
      /** 儲存已變更資料 */
      save?: (params: {
        insertRows?: any[];
        updateRows?: any[];
        deleteRows?: any[];
      }) => Promise<void>;
    }>
  },
  setup(props) {
    const model = CloudFun.current?.model

    const grid = ref<any>();
    const gridOptions: GridOptions = {
      title: '問題',
      canCreate: true,
      canUpdate: true,
      canDelete: true,
      multiselect: false,
      showFooter: true,      
      columns: [
        { field: 'Title', title: '問題描述', showOverflow: true, sortable: true, resizable: false },        
        {
          field: 'Type',
          title: '類型',
          showOverflow: true, sortable: true, resizable: false,
          formatter: ({ cellValue }) => {
            const item = model?.enums && Object.values(model?.enums.QuestionType).find((e) => e.Value === cellValue);
            return item ? item.Name : "";
          }
        },
        { field: "Ordinal", title: "排序", showHeaderOverflow: true, showOverflow: true, sortable: true, resizable: false, align: 'center' },
        // { field: 'DueDate', title: '優惠截止日期', showOverflow: true, sortable: true, resizable: false, formatter: ({ cellValue }) => formatDate(new Date(cellValue), 'yyyy/MM/dd') },
      ],
      promises: { query: props.promises?.query, queryAll: props.promises?.queryAll, save: props.promises?.save },
      modalConfig: { width: "60%", height: "600", showFooter: true },
    }

    const formOptions: VxeFormProps = {
      titleWidth: 100,
      titleAlign: 'right',
      items: [
        {
          field: 'Title',
          title: '問題描述',
          span: 12,
          itemRender: { name: '$input', props: { type: 'text', placeholder: '請輸入文字' }, }
        },
        {
          field: 'SerialNumber',
          title: '問題序號',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        },
        {
          field: 'Type',
          title: '類型',
          span: 12,
          itemRender: { name: "$select", options: model ? Object.values(model.enums.QuestionType).map(e => { return { label: e.Name, value: e.Value } }) : [] }
        },
        { field: 'Ordinal', title: '排序', span: 12, itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入數字', clearable: true } } },
        {
          field: 'Options',
          span: 24,
          slots: { default: "column-option" },
        },
      ],
      rules:{
        Title: [{ type: 'string', required: true }],
      }
    }

    const initData = (row: any, callback: any) => {
      console.log(row)
      row.Ordinal = 0;
      row.Type = 0;
      row.QnrId = props.sid;
      callback();
    }

    const optionGrid = ref<any>()
    const optionModal = reactive({
      visible: false,
      selectedRow: {} as any,
      gridOptions: {
        promises: {
          query: model ? (params) => {
            // params.condition = new Condition()
            //   .and("ActivityId", Operator.Equal, optionModal.selectedRow.Id.toString())
            //   .and(params.condition!);
            if (!params.sortings || params.sortings?.length === 0)
              params.sortings = [{ column: "CreatedTime", order: 1 }];
            return model.dispatch("option/query", params);
          } : undefined,
          queryAll: model ? () => model.dispatch('option/query') : undefined,
          save: model ? (params) => model.dispatch('option/save', params) : undefined
        }
      } as GridOptions,
      show(row: any) {
        optionModal.visible = true;
        optionModal.selectedRow = row;
        console.log(row)
        if (optionGrid.value) optionGrid.value.refresh();
      }
    })

    return {
      grid,
      initData,
      gridOptions,
      formOptions,
      optionModal,
      optionGrid,
    }
  },
  methods: {
    refresh() {
      (this.$refs.grid as any).refresh();
    },
    async onGridEdit (row: any, callback: any) {
      // const entity = row.Id ? await this.$model.dispatch('documentRecord/find', row.Id) : undefined
      // Object.assign(row, entity)
      // const statuses = (Object.values(this.$model.enums.DocumentStatus) as any[]);
      // row.BeforeStatusName = statuses.find(e => e.Value === row.BeforeStatus).Name;
      // row.AfterStatusName = statuses.find(e => e.Value === row.AfterStatus).Name;
      //   if (entity) {
      //     entity.Xml.Time = new Date(entity.Xml.Time)
      //   }
      callback()
    },
    hideBatchDropDown () {
      cash('#batch-dropdown').dropdown('hide')
    },
  }
});
