<template>
  <fieldset class="border rounded my-2 ml-4 p-2">
    <div class="flex items-center mx-1 mb-2">
      問題選項
    </div>
    <vxe-form class="" ref="speakerForm" :data="table.formData" :rules="table.rules" titleAlign="right" @submit="table.save">
        <vxe-form-item title="選項文字" field="Title" :item-render="{}" span="7">
          <template #default="{ data }">
            <vxe-input type="text" v-model="data.Title" placeholder="請輸入文字" clearable></vxe-input>
          </template>
        </vxe-form-item>
        <vxe-form-item title="可附加內容" field="Added" :item-render="{}" span="7">
          <template #default="{ data }">
            <vxe-select v-model="data.Added" placeholder="請選擇" clearable>
              <vxe-option :value="false" label="否"></vxe-option>
              <vxe-option :value="true" label="是"></vxe-option>
            </vxe-select>
          </template>
        </vxe-form-item>
        <vxe-form-item title="排序" field="Ordinal" :item-render="{}" span="7">
          <template #default="{ data }">
            <vxe-input type="number" v-model="data.Ordinal" placeholder="請輸入排序" clearable></vxe-input>
          </template>
        </vxe-form-item>
        <vxe-form-item :item-render="{}" span="3">
          <template #default>
            <vxe-button type="submit" status="primary" content="確認">
            </vxe-button>
          </template>
        </vxe-form-item>
    </vxe-form>
    <vxe-table class="mb-2" ref="grid" :data="table.data" v-bind="gridOptions.props">
      <vxe-column field="Title" title="選項文字">
      </vxe-column>
      <vxe-column field="Added" title="可附加內容" :formatter="({ cellValue }) => cellValue ? '是' : '否'">
      </vxe-column>
      <vxe-column field="Ordinal" title="排序" sortable>
      </vxe-column>
      <vxe-column v-if="!readonly" title="操作" width="150">
        <template #default="{ row }">
          <div class="flex justify-content">
            <!-- <vxe-button @click="table.update(row)">編輯</vxe-button> -->
            <vxe-button @click="table.delete(row)">刪除</vxe-button>
          </div>
        </template>
      </vxe-column>
    </vxe-table>
    <!-- <vxe-modal v-model="table.isEditing" :title="table.selectedRow ? '編輯演講人' : '新增演講人'" width="70%" min-width="600"
      height="400" :loading="table.isLoading" resize destroy-on-close showFooter>
      <template #default>
        <vxe-form ref="form" :data="table.formData" titleAlign="right" @submit="table.save">
           <vxe-form-item title="是否為外部作者" field="OutSource" :item-render="{}" span="24" titleWidth="150">
            <template #default="{ data }">
              <vxe-checkbox v-model="data.OutSource" :checked-value="false" :unchecked-value="true" @change="evt => { if(evt.checked) { data.Name = null; } else { data.SpeakerId = null; } }"></vxe-checkbox>
             </template>
          </vxe-form-item>
          <vxe-form-item title="姓名" field="Name" :item-render="{}" span="12">
            <template #default="{ data }">
              <vxe-input v-if="!data.OutSource" v-model="data.Name" placeholder="請輸入姓名" clearable></vxe-input>
              <select-box v-else v-bind="userSelectorOptions" v-model="data.SpeakerId" />
            </template>
          </vxe-form-item>
          <vxe-form-item title="撰稿費用" field="Fee" :item-render="{}" span="12">
            <template #default="{ data }">
              <vxe-input type="number" v-model="data.Fee" placeholder="請輸入撰稿費用" clearable></vxe-input>
             </template>
          </vxe-form-item>
          <vxe-form-item title="付款狀態" field="PayStatus" :item-render="{}" span="12">
            <template #default="{ data }">
              <vxe-select v-model="data.PayStatus" placeholder="請選擇付款狀態" clearable>
                <vxe-option v-for="(item) in Object.values($enums.PayStatus)" :key="'pay-status-' + item.Value" :value="item.Value" :label="item.Name"></vxe-option>
              </vxe-select>
             </template>
          </vxe-form-item>
          <vxe-form-item title="排序" field="Ordinal" :item-render="{}" span="12">
            <template #default="{ data }">
              <vxe-input type="number" v-model="data.Ordinal" placeholder="請輸入排序" clearable></vxe-input>
             </template>
          </vxe-form-item>
          <vxe-form-item title="外部來源" field="Source" :item-render="{}" span="24">
            <template #default="{ data }">
              <vxe-textarea v-model="data.Source" placeholder="請輸入外部來源" clearable></vxe-textarea>
             </template>
          </vxe-form-item>
       </vxe-form>
      </template>
      <template #footer>
        <vxe-button type="button" status="primary" content="確認"
          @click="$refs.form.validate((errMap) => { if (errMap === undefined) $refs.form.dispatchEvent('submit'); })">
        </vxe-button>
      </template>
    </vxe-modal> -->
  </fieldset>
</template>
<script lang="ts">
/* eslint-disable */
import SelectBox, { SelectBoxOptions } from "@/cloudfun/components/SelectBox.vue";
import CloudFun, { defineComponent, ref, reactive, PropType, Condition, Operator } from "@cloudfun/core";
import { watch } from "vue";
import { VxeTableProps } from "vxe-table";

interface Option {
  Title: string;
  Added: boolean;
  Ordinal: number;
  QuestionId: number;
}

export default defineComponent({
  name: "OptionTable",
  components: {
    SelectBox
  },
  props: {
    data: {
      type: Array as PropType<Option[]>,
      default: () => []
    },
    aid: Number,
    readonly: Boolean,
  },
  setup(props, { emit }) {
    const model = CloudFun.current?.model;
    const grid = ref<any>();
    const gridOptions = {
      props: {
        sortConfig: {
          defaultSort: { field: 'Ordinal', order: 'asc' }
        }
      } as VxeTableProps,
      insert() {
        const { row } = grid.value.insertAt({}, 0);
        grid.value.setActiveRow(row)
      }
    }

    const table = reactive({
      data: [] as Option[],
      isLoading: false,
      isEditing: false,
      formData: { Ordinal: 0, Added: false } as Option,
      selectedRow: null as Option | null,
      // insert() {
      //   table.formData = { OutSource: false, Fee: 0, PayStatus: 0, Ordinal: 0 };
      //   table.selectedRow = null;
      //   table.isEditing = true;
      // },
      update(row: Option) {
        table.formData = { ...row };
        table.selectedRow = row;
        table.isEditing = true;
      },
      delete(row: Option) {
        grid.value.remove(row);
      },
      async save() {
        // if (table.formData.SpeakerId) {
        //   const temp = await model?.dispatch("option/find", table.formData.SpeakerId)
        //   table.formData.Option = { Id: temp.Id, Name: temp.Name, Photo: null };
        //   //table.formData.AgendaId = props.aid!;
        // }

        await grid.value.insert(table.formData);
        grid.value.sort({ field: "Ordinal", order: 'asc' });

        //emit('refresh');
      },
      rules: {
        Title: [{ required: true }],
        Added: [{ required: true }],
        Ordinal: [{ required: true }],
      }
    })

    watch(() => props.data, async(value) => {
      console.log(value)
      // const SpeakerIds = value.filter(e => e.SpeakerId).map(e => e.SpeakerId);
      // const Speakers: Option['Option'][] = await model?.dispatch("option/query", { condition: new Condition("Id", Operator.In, SpeakerIds) });
      table.data = value.map(e => {
        // if (e.SpeakerId) {
        //   const option = Speakers.find(a => a && a.Id === e.SpeakerId)
        //   if(option) e.Option = { Id: option!.Id, Name: option!.Name, Photo: null };
        // } else {
        // }
        return e;
      }).sort((a, b) => a.Ordinal - b.Ordinal);
    }, { immediate: true });

    const speakerForm = ref<any>();

    const userSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "Name",
      valueField: "Id",
      columns: [
        {
          field: "Name",
          title: "姓名",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        }
      ],
      showHeader: true,
      promises: {
        find: value => model!.dispatch("option/find", value), // eslint-disable-line
        query: params => model!.dispatch("option/query", params) // eslint-disable-line
      }
    };
    return {
      table,
      grid,
      gridOptions,
      speakerForm,
      userSelectorOptions
    }
  },
  methods: {
    getData() {
      return this.grid.getTableData().fullData;
    }
  }
})
</script>
