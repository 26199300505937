<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">問卷管理</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <div v-show="!grid.isEditing && grid.isRowSelected" id="batch-dropdown" class="dropdown ml-auto mr-2 sm:ml-0">
          <button class="dropdown-toggle button px-2 box bg-blue-500 text-white flex items-center">
            <FontAwesome icon="tasks" type="fas" class="w-4 h-4 mr-1" />
            群組操作
          </button>
          <div class="dropdown-box">
            <div class="dropdown-box__content box dark:bg-dark-1 p-2 w-32">
              <!-- <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.removeSelectedRows"
              >
                <FontAwesome icon="trash" type="fas" class="w-4 h-4 mr-2" /> 刪除
              </span> -->
              <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onPrint({ mode: 'selected' })"
              >
                <FontAwesome icon="print" type="fas" class="w-4 h-4 mr-2" /> 列印
              </span>
              <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onExport({ type: 'csv', mode: 'selected' })"
              >
                <FontAwesome icon="file-csv" type="fas" class="w-4 h-4 mr-2" /> 匯出 CSV
              </span>
              <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onExport({ type: 'html', mode: 'selected' })"
              >
                <FontAwesome icon="file" type="fas" class="w-4 h-4 mr-2" /> 匯出 HTML
              </span>
            </div>
          </div>
        </div>
        <button v-show="!grid.isEditing" class="button text-white bg-theme-1 shadow-md mr-2 flex items-center" @click="grid.addNewRow">
          <FontAwesome icon="plus" type="fas" class="w-4 h-4 mr-1" />
          新增
        </button>
      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <div class="xl:flex sm:mr-auto">
          <div class="sm:flex items-center ml-1 sm:mr-2 mt-2 xl:mt-0">
            <input type="search" class="input w-full sm:w-40 xxl:w-full mt-2 sm:mt-0 border" v-model.trim="grid.keyword" placeholder="請輸入關鍵字" />
          </div>
          <div class="mt-2 xl:mt-0">
            <button type="button" class="button w-full sm:w-16 bg-theme-1 text-white" @click="grid.refresh">
              搜尋
            </button>
          </div>
        </div>
      </div>
      <div class="overflow-x-auto scrollbar-hidden mt-2">
        <grid
          ref="grid"
          v-bind="gridOptions"
          @addNewRow="(row, callback) => { row.Published = false; callback(); }"
          @edit="onGridEdit"
          @removeSelectedRows="hideBatchDropDown()"
          @modalResize="onGridModalResize"
        >
          <template #question="{ row }">
            <vxe-button type="button" content="查看" @click="questionModal.show(row)"></vxe-button>
          </template>
          <template #modal="{ row, submit, reset }">
            <vxe-form
              ref="form"
              v-bind="formOptions"
              :data="row"
              @reset="reset"
              @submit="submit"
            >
            </vxe-form>
          </template>
          <template #modal-footer>
            <vxe-button type="submit" status="primary" content="確認" @click="$refs.form.validate((errMap) => { if (errMap === undefined) $refs.form.dispatchEvent('submit'); })"></vxe-button>
            <vxe-button type="reset" content="重置" @click="$refs.form.dispatchEvent('reset')"></vxe-button>
          </template>
          <template #filter-name="{ column, $panel }">
            <div v-for="(option, index) in column.filters" :key="index" class="flex item-center justify-center">
              <input type="checkbox"
                class="input input--switch border mt-4 ml-2"
                :checked="option.checked"
                @input="grid.changeFilterEvent(event, option, $panel, true)"
              />
              <div class="relative m-2">
                <div class="absolute rounded-l-md w-10 h-full flex items-center justify-center bg-gray-100 dark:bg-dark-1 dark:border-dark-4 border text-gray-600">
                  {{option.label}}
                </div>
                <input
                  v-model.trim="option.data"
                  :disabled="!option.checked"
                  type="text"
                  class="input pl-12 w-full border col-span-4"
                  placeholder="請輸入文字"
                  @input="grid.changeFilterEvent(event, option, $panel)"
                />
              </div>
            </div>
          </template>
        </grid>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>
  <vxe-modal v-model="questionModal.visible" width="80%" title="問題管理" height="700" show-zoom="true">
    <QuestionTable ref="questionGrid" v-bind="questionModal.gridOptions" :sid="questionModal.selectedRow.Id" />
  </vxe-modal>
</template>

<script lang="ts">
/* eslint-disable */
import CloudFun, { Condition, defineComponent, Operator, ref, onMounted, reactive } from '@cloudfun/core'
import Grid, { GridOptions } from '@/cloudfun/components/Grid.vue'
import { VxeFormProps } from 'vxe-table'
import SelectBox, { SelectBoxOptions } from '@/cloudfun/components/SelectBox.vue'
import FileUploader from "@/cloudfun/components/FileUploader.vue";
import QuestionTable from "./QuestionTable.vue";
import { VueUploadItem } from "vue-upload-component";

export default defineComponent({
  components: {
    Grid,
    QuestionTable,
  },
  setup () {
    const model = CloudFun.current?.model
    const grid = ref<any>({});
    const qnrGrid = ref<any>({});
    var printColumns = [
      { field: 'Name' },
      { field: 'Description' }
    ]

    const gridOptions: GridOptions = {
      stripe: false,
      title: '問卷',
      toolbarConfig: {
        custom: true,
        refresh: true
      },
      multiselect: false,      
      printConfig: { sheetName: '問卷清單', columns: printColumns, modes: ['current', 'selected', 'all'] },
      exportConfig: { filename: '問卷清單', type: 'csv', types: ['html', 'csv'], mode: 'all', modes: ['current', 'selected', 'all'], columns: printColumns },
      columns: [{
        field: 'Title',
        title: '標題',
        showHeaderOverflow: true,
        showOverflow: true,
        sortable: true,
        treeNode: false,
        editRender: { name: '$input', immediate: true, attrs: { type: 'text' } },
        filters: [{ checked: false, label: '包含' }],
        slots: { filter: 'filter-name' }
      },
      { field: 'Number', title: '編號', showHeaderOverflow: true, showOverflow: true, sortable: true },
      { field: 'Published', title: '已發布', showHeaderOverflow: true, showOverflow: true, sortable: true, formatter: ({ cellValue }) => cellValue ? '是' : '否', align: 'center' },
      {
        title: '問題管理',
        width: '100',
        slots: { default: "question" }, align: 'center' 
      },
      ],
      promises: {
        query: model ? (params) => {
          // if (params?.sortings?.length === 0) {
          //   params.sortings.push({ column: 'Ordinal', order: 0 }, { column: 'CreatedTime', order: 1 });
          // }
          // if (params.condition) params.condition.and('ParentId', Operator.IsNull)
          // else params.condition = new Condition('ParentId', Operator.IsNull)
          return model.dispatch('qnr/query', params)
        } : undefined,
        queryAll: model ? () => model.dispatch('qnr/query') : undefined,
        save: model ? (params) => model.dispatch('qnr/save', params) : undefined
      },
      modalConfig: { width: "50%", height: "380", showFooter: true },
      // treeConfig: { children: 'Children' }
    }

    const formOptions: VxeFormProps = {
      titleWidth: 80,
      titleAlign: 'right',
      items: [
        {
          field: 'Title',
          title: '名稱',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        },
        {
          field: 'Number',
          title: '編號',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '由系統自動產生', readonly: true }, attrs: { type: 'text' } }
        },
        {
          field: 'Published',
          title: '已發布',
          span: 12,
          titleWidth: 80,
          itemRender: { name: '$select', options: [{ value: false, label: '否' }, { value: true, label: '是' }] }
        },
        { field: 'Introduction', title: '說明', span: 24, itemRender: { name: '$textarea', props: { autosize: { minRows: 2, maxRows: 4 }, placeholder: '請輸入文字' } } },
        { field: 'Remark', title: '備註', span: 24, itemRender: { name: '$textarea', props: { autosize: { minRows: 2, maxRows: 4 }, placeholder: '請輸入文字' } } },
        // {
        //   span: 24,
        //   titleWidth: 80,
        //   slots: { default: "column-question" }
        // },
      ],
      rules: {
        Name: [{ required: true }],
      }
    }

    const questionForm = ref<any>();

    const qnrTable = reactive({
      gridOptions: {
        stripe: false,
        title: '問題',
        toolbarConfig: {
          custom: true,
          refresh: true
        },
        canUpdate: false,
        canDelete: false,
        canRead:false,
        pagerConfig:{
          layouts: [
            "PrevPage",
            "Jump",
            "PageCount",
            "NextPage",
          ],
        },
        multiselect: false,
        printConfig: { sheetName: '問題清單', columns: printColumns, modes: ['current', 'selected', 'all'] },
        exportConfig: { filename: '問題清單', type: 'csv', types: ['html', 'csv'], mode: 'all', modes: ['current', 'selected', 'all'], columns: printColumns },
        columns: [{
          field: 'Title',
          title: '標題',
          showHeaderOverflow: true,
          showOverflow: true,
        },
        { field: 'Ordinal', title: '排序', showHeaderOverflow: true, showOverflow: true, sortable: true },
        {
          field: "operate",
          title: "操作",
          align: "center",
          width: 150,
          slots: { default: "operate" }
        }
        ],
        promises: {
          query: model ? (params) => {
            if (params?.sortings?.length === 0) {
              params.sortings.push({ column: 'Ordinal', order: 0 }, { column: 'CreatedTime', order: 1 });
            }
            // if (params.condition) params.condition.and('ParentId', Operator.IsNull)
            // else params.condition = new Condition('ParentId', Operator.IsNull)
            return model.dispatch('question/query', params)
          } : undefined,
          queryAll: model ? () => model.dispatch('question/query') : undefined,
          save: model ? (params) => model.dispatch('question/save', params) : undefined
        },
        modalConfig: { width: "50%", height: "500", showFooter: true },
      } as GridOptions,
      addNewRow: (row: any, callback: any) => {
        row.Ordinal = 0;
        callback();
      },
      formOptions: {
        titleWidth: 80,
        titleAlign: 'right',
        items: [
          {
            field: 'Title',
            title: '名稱',
            span: 12,
            itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
          },          
          {
            field: 'Ordinal',
            title: '排序',
            span: 12,
            itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入數字' }, }
          },
        ],
        rules: {
          Title: [{ required: true }],
        }
      } as VxeFormProps,
      onGridEdit: async(row: any, callback: any) => {
        const entity = row.Id ? await model?.dispatch("question/find", row.Id) : undefined;
        if (entity) Object.assign(row, entity);        
        callback();
      },
      reset: (row: any) => {
        qnrGrid?.value.reset(row);
      },     
      submit: (row: any) => {      
        if(!row.Id) {
          console.log('insert', row)
          model?.dispatch("question/insert", row).then((data: any) =>{
            if(data && data.Id) {
              qnrGrid?.value.refresh();
              qnrGrid.value.isModalPopup = false;
              qnrGrid.value.editingRow = null;
            }
          }).catch((failure: any) => {
            const content = failure.message;
            CloudFun.send("error", {
              subject: '新增失敗',
              content,
            });
          });
        }        
      },
      remove: (row: any, callback: any) => {
        if(confirm("確定要刪除此筆資料嗎?")) {
          console.log(row);
          // model?.dispatch("question/delete", row.Id).then(() =>{
          //   console.log('ok')
          //   qnrGrid?.value.refresh();
          // }).catch((failure: any) => {
          //   const content = failure.message;
          //   CloudFun.send("error", {
          //     subject: '刪除失敗',
          //     content,
          //   });
          // });
        }
      }
    })

    const questionGrid = ref<any>()
    const questionModal = reactive({
      visible: false,
      selectedRow: {} as any,
      gridOptions: {
        promises: {
          query: model ? (params) => {
            console.log(questionModal.selectedRow.Id);
            params.condition = new Condition()
              .and("QnrId", Operator.Equal, questionModal.selectedRow.Id.toString())
              .and(params.condition!);
            if (!params.sortings || params.sortings?.length === 0)
              params.sortings = [{ column: "Ordinal", order: 0 }, { column: 'CreatedTime', order: 1 }];
            return model.dispatch("question/query", params);
          } : undefined,
          queryAll: model ? () => model.dispatch('question/query') : undefined,
          save: model ? (params) => model.dispatch('question/save', params) : undefined
        }
      } as GridOptions,
      show(row: any) {
        questionModal.visible = true;
        console.log(row)
        questionModal.selectedRow = row;
        if (questionGrid.value) questionGrid.value.refresh();
      }
    })

    const changeUser = (data: any, name: string) => {
      if (data && name) data.Name = name;
    };

    const headers = {
      Authorization: `Basic ${model?.getCertificate(0)}`
    };

   
    return {
      grid,
      gridOptions,
      formOptions,
      headers,
      changeUser,
      qnrTable,
      qnrGrid,
      questionForm,
      questionModal,
      questionGrid,
    }
  },
  methods: {
    hideBatchDropDown () {
      cash('#batch-dropdown').dropdown('hide')
    },
    async uploaderFilter (current: VueUploadItem, original: VueUploadItem, prevent: any) {
      if (!current || !current.name) return prevent();
      if (!/\.(png|gif|jpg|jpeg)$/i.test(current.name)) {
        alert("未支援此種圖片格式");
        return prevent();
      }
    },
    async onGridEdit(row: any, callback: any) {
      const entity = row.Id
        ? await this.$model.dispatch("qnr/find", row.Id)
        : undefined;
      if (entity) {
        if (entity.Keywords && entity.Keywords.length) {
          entity.KeywordIds = entity.Keywords.map((e: any) => e.KeywordId);
        } else {
          entity.KeywordIds = [];
        }
        Object.assign(row, entity);
      }
      callback();
    }
  }
})
</script>
<style>
  .vxe-select-option{
    max-width: 100% !important;
  }
  .vxe-modal--content .vxe-grid--pager-wrapper > .vxe-pager {
    display: block !important;
    margin: 5px;
  }
</style>